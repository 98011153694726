import React from "react";

function ArrowRightSvg() {
    return (
        <svg id="arrow-point-to-right" xmlns="http://www.w3.org/2000/svg" width="7.41" height="13"
             viewBox="0 0 7.41 13">
                <path id="arrow-right" data-name="Path 47" className="cls-1"
                  d="M104.282,7.143l-5.59,5.59a.91.91,0,0,1-1.287-1.287L102.351,6.5,97.405,1.554A.91.91,0,0,1,98.693.266l5.59,5.59a.91.91,0,0,1,0,1.287Z"
                  transform="translate(-97.139 0)"/>
        </svg>


    )
}

export default ArrowRightSvg;