import React from "react";
import LongArrowRightSvg from "../svg/longArrowRight";

function SliderItem(props) {
    return (
        <div className="work-block-small">
            <div>
                <div className="work-block-small-img" style={{backgroundImage: `url(../static/images${props.img}`}}
                     onClick={props.handleClick}>
                </div>
                <div className="work-block-small-content">
                    <p className="sm">{props.title}</p>
                    <h4><span className="view-more" onClick={props.handleClick}>View More <LongArrowRightSvg/></span>
                    </h4>
                </div>
            </div>
        </div>
    )
}

export default SliderItem;