import React, {useState} from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';
import LightboxModal from "./WorkModal/SliderLightbox";
import SliderItem from "./SliderItem";
import ArrowLeftSvg from "../svg/arrowLeft";
import ArrowRightSvg from "../svg/arrowRight";


const WorksSlider = (props) => {
    const [swiper, setSwiper] = useState(null);
    const [modalShow, setModalShow] = React.useState({show: false, index: 0});


    const
        params = {

            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next'
            },
            shouldSwiperUpdate:true,
            breakpoints: {
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 32
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 30
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                320: {
                    slidesPerView: 'auto',
                    spaceBetween: 15
                }
            }

        }
    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    return (
        <>
            <div className="swiperHeight">
                <Swiper getSwiper={setSwiper} {...params}>
                    {props.json &&
                    props.json.map((work, index) => <div key={work.title + index} className="swiper-slide"><SliderItem title={work.title}
                                                                                              img={work.img}
                                                                                              viewMore={props.viewMore}
                                                                                              handleClick={() => setModalShow({
                                                                                                  index: index,
                                                                                                  show: true
                                                                                              })}/>
                        </div>
                    )}
                </Swiper>
            </div>
            <div className="btnsWrap">
                <button onClick={goPrev} className="swiper-button-prev swiper-btn"><ArrowLeftSvg/></button>
                <button onClick={goNext} className="swiper-button-next swiper-btn"><ArrowRightSvg/></button>
            </div>
            <LightboxModal show={modalShow.show}
                           onHide={() => setModalShow({show: false})} slideindex={modalShow.index} json={props.json}
                           key={"workSlide-" + modalShow.index}/>

        </>
    );
};

export default WorksSlider;

