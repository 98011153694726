import React from "react";

function AirConditioningSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="64.484" height="49.809" viewBox="0 0 64.484 49.809">
            <g id="air-conditioner" transform="translate(-463 -363)">
                <g id="Group_5" data-name="Group 5">
                    <g id="Group_4" data-name="Group 4">
                        <g id="Path_22" data-name="Path 22">
                            <path id="Path_77" data-name="Path 77"
                                  d="M503.8,368.2a19.708,19.708,0,0,0-14.7,32.833,1.107,1.107,0,0,0,1.651-1.475,17.477,17.477,0,1,1,2.887,2.59,1.107,1.107,0,0,0-1.287,1.8A19.708,19.708,0,1,0,503.8,368.2Z"/>
                        </g>
                    </g>
                </g>
                <g id="Group_7" data-name="Group 7">
                    <g id="Group_6" data-name="Group 6">
                        <g id="Path_23" data-name="Path 23">
                            <path id="Path_78" data-name="Path 78"
                                  d="M520.58,363h-53.1A4.49,4.49,0,0,0,463,367.485v35.858a1.107,1.107,0,1,0,2.214,0V367.485a2.275,2.275,0,0,1,2.271-2.271h53.094a1.107,1.107,0,0,0,0-2.214h0Z"/>
                        </g>
                    </g>
                </g>
                <g id="Group_9" data-name="Group 9">
                    <g id="Group_8" data-name="Group 8">
                        <g id="Path_24" data-name="Path 24">
                            <path id="Path_79" data-name="Path 79"
                                  d="M523,363h-3.582a1.107,1.107,0,0,0,0,2.214H523a2.275,2.275,0,0,1,2.271,2.271v40.839A2.274,2.274,0,0,1,523,410.593H467.485a2.274,2.274,0,0,1-2.271-2.269v-6.29a1.107,1.107,0,0,0-2.214,0v6.29a4.49,4.49,0,0,0,4.485,4.485H523a4.49,4.49,0,0,0,4.485-4.485V367.485A4.49,4.49,0,0,0,523,363Z"/>
                        </g>
                    </g>
                </g>
                <g id="Group_11" data-name="Group 11">
                    <g id="Group_10" data-name="Group 10">
                        <g id="Path_25" data-name="Path 25">
                            <path id="Path_80" data-name="Path 80" className="cls-1"
                                  d="M516.588,382.135a7.1,7.1,0,0,0-6.774-.543l-3.389,1.5a5.4,5.4,0,0,0-1.308-.509v-9.6a1.107,1.107,0,0,0-1.107-1.107,7.128,7.128,0,0,0-6.515,10.015l1.5,3.387a5.414,5.414,0,0,0-.51,1.31h-9.6a1.107,1.107,0,0,0-1.107,1.107,7.12,7.12,0,0,0,10.015,6.515l3.386-1.5a5.417,5.417,0,0,0,1.31.511v9.6a1.107,1.107,0,0,0,1.107,1.107,7.129,7.129,0,0,0,6.516-10.016l-1.5-3.387a5.434,5.434,0,0,0,.508-1.308h9.6a1.108,1.108,0,0,0,1.107-1.107A7.1,7.1,0,0,0,516.588,382.135ZM496.9,392.186a4.913,4.913,0,0,1-6.779-3.385H498.4a5.506,5.506,0,0,0,.956,2.3Zm3-15.862a4.894,4.894,0,0,1,3.01-2.109V382.5a5.506,5.506,0,0,0-2.3.956l-1.089-2.459a4.9,4.9,0,0,1,.375-4.669Zm3.776,14.841h-.031a3.211,3.211,0,0,1-1.736-.6l-.034-.023a3.276,3.276,0,0,1-1.34-2.637v-.157a3.235,3.235,0,0,1,.6-1.74,3.272,3.272,0,0,1,2.659-1.37h.157a3.229,3.229,0,0,1,1.739.6,3.27,3.27,0,0,1,1.371,2.66v.156a3.237,3.237,0,0,1-.6,1.738,3.274,3.274,0,0,1-2.661,1.372.772.772,0,0,1-.128,0Zm4.045,8.314a4.894,4.894,0,0,1-3.01,2.109V393.3a5.494,5.494,0,0,0,2.3-.957l1.089,2.459a4.9,4.9,0,0,1-.383,4.673ZM509.211,387a5.511,5.511,0,0,0-.956-2.3l2.46-1.089a4.916,4.916,0,0,1,6.485,2.506,4.866,4.866,0,0,1,.294.88h-8.283Z"/>
                        </g>
                    </g>
                </g>
                <g id="Group_13" data-name="Group 13">
                    <g id="Group_12" data-name="Group 12">
                        <g id="Path_26" data-name="Path 26">
                            <path id="Path_81" data-name="Path 81"
                                  d="M480.577,370.3h-11.51a1.107,1.107,0,0,0,0,2.214h11.514a1.107,1.107,0,0,0,0-2.214Z"/>
                        </g>
                    </g>
                </g>
                <g id="Group_15" data-name="Group 15">
                    <g id="Group_14" data-name="Group 14">
                        <g id="Path_27" data-name="Path 27">
                            <path id="Path_82" data-name="Path 82"
                                  d="M480.577,386.8h-11.51a1.107,1.107,0,0,0,0,2.214h11.51a1.107,1.107,0,0,0,0-2.214Z"/>
                        </g>
                    </g>
                </g>
                <g id="Group_17" data-name="Group 17">
                    <g id="Group_16" data-name="Group 16">
                        <g id="Path_28" data-name="Path 28">
                            <path id="Path_83" data-name="Path 83"
                                  d="M480.577,397.787h-11.51A1.107,1.107,0,0,0,469.06,400h11.517a1.107,1.107,0,0,0,.007-2.214Z"/>
                        </g>
                    </g>
                </g>
                <g id="Group_19" data-name="Group 19">
                    <g id="Group_18" data-name="Group 18">
                        <g id="Path_29" data-name="Path 29">
                            <path id="Path_84" data-name="Path 84"
                                  d="M480.577,392.294h-11.51a1.107,1.107,0,0,0-.007,2.214h11.517a1.107,1.107,0,0,0,.007-2.214Z"/>
                        </g>
                    </g>
                </g>
                <g id="Group_21" data-name="Group 21">
                    <g id="Group_20" data-name="Group 20">
                        <g id="Path_30" data-name="Path 30">
                            <path id="Path_85" data-name="Path 85"
                                  d="M480.577,403.288h-11.51a1.107,1.107,0,0,0-.007,2.214h11.517a1.107,1.107,0,0,0,.007-2.214Z"/>
                        </g>
                    </g>
                </g>
                <g id="Group_23" data-name="Group 23">
                    <g id="Group_22" data-name="Group 22">
                        <g id="Path_31" data-name="Path 31">
                            <path id="Path_86" data-name="Path 86"
                                  d="M480.578,381.3h-1.254a1.107,1.107,0,0,0,0,2.214h1.254a1.107,1.107,0,0,0,0-2.214Z"/>
                        </g>
                    </g>
                </g>
                <g id="Group_25" data-name="Group 25">
                    <g id="Group_24" data-name="Group 24">
                        <g id="Path_32" data-name="Path 32">
                            <path id="Path_87" data-name="Path 87"
                                  d="M480.634,381.3H469.067a1.107,1.107,0,0,0,0,2.214h11.567a1.107,1.107,0,0,0,0-2.214Z"/>
                        </g>
                    </g>
                </g>
                <g id="Group_27" data-name="Group 27">
                    <g id="Group_26" data-name="Group 26">
                        <g id="Path_33" data-name="Path 33">
                            <path id="Path_88" data-name="Path 88"
                                  d="M480.577,375.8h-11.51a1.107,1.107,0,0,0,0,2.214h11.51a1.107,1.107,0,0,0,0-2.214Z"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    )
}

export default AirConditioningSvg;