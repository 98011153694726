import React from "react";

function LongArrowRightSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10">
            <defs>
                <style>

                </style>
            </defs>
            <g id="long-arrow-right" transform="translate(0 -41.916)">
                <path id="Path_35" data-name="Path 35" className="cls-1"
                      d="M14.8,46.438l-4.464-4.324a.714.714,0,0,0-.986,0,.661.661,0,0,0,0,.956l3.273,3.171H.7a.676.676,0,1,0,0,1.351H12.619L9.346,50.763a.661.661,0,0,0,0,.956.714.714,0,0,0,.986,0L14.8,47.394A.661.661,0,0,0,14.8,46.438Z"
                      transform="translate(0 0)"/>
            </g>
        </svg>


    )
}

export default LongArrowRightSvg;