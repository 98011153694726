import React from "react";

function TelegramSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24.443" height="20.68" viewBox="0 0 24.443 20.68">
            <path id="telegram" className="cls-1"
                  d="M24.065.566A1.587,1.587,0,0,0,22.806,0a2.47,2.47,0,0,0-.879.175l-20.69,7.9C.139,8.492-.009,9.121,0,9.458s.193.957,1.313,1.313l.02.006,4.292,1.228,2.321,6.637A2,2,0,0,0,9.8,20.11a2.194,2.194,0,0,0,1.484-.632l2.655-2.444,3.85,3.1h0l.037.029.01.008a2.223,2.223,0,0,0,1.351.507h0A1.9,1.9,0,0,0,21,19l3.39-16.74A2,2,0,0,0,24.065.566Zm-17,11.207,8.28-4.23-5.156,5.478a.715.715,0,0,0-.174.319l-.994,4.027Zm3.253,6.652c-.034.032-.069.06-.1.086l.922-3.736,1.678,1.351Zm12.67-16.45-3.39,16.74c-.033.16-.137.533-.4.533a.835.835,0,0,1-.469-.2l-4.363-3.513h0l-2.6-2.09,7.456-7.922a.716.716,0,0,0-.847-1.128L6.1,10.653,1.754,9.409l20.684-7.9a1.05,1.05,0,0,1,.368-.081c.044,0,.122.005.151.041a.683.683,0,0,1,.026.5Zm0,0"
                  transform="translate(0)"/>
        </svg>

    )
}

export default TelegramSvg;