import React from "react";

function HeatingSvg() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="49" height="105.152" viewBox="0 0 49 105.152">
            <g id="heating-icon" transform="translate(-470 -335.572)">
                <g id="Group_2" data-name="Group 2">
                    <g id="Rectangle_46" data-name="Rectangle 46">
                        <g id="Group_43" data-name="Group 43">
                            <path id="Path_48" data-name="Path 48" className="cls-1"
                                  d="M488,347h14a5,5,0,0,1,5,5v2H483v-2A5,5,0,0,1,488,347Z"/>
                        </g>
                        <g id="Group_44" data-name="Group 44">
                            <path id="Path_49" data-name="Path 49" className="cls-2"
                                  d="M504.625,354h-19.25A2.377,2.377,0,0,1,483,351.625,4.631,4.631,0,0,1,487.625,347h14.75A4.631,4.631,0,0,1,507,351.625,2.377,2.377,0,0,1,504.625,354ZM485.5,351.5H504.5a2.128,2.128,0,0,0-2.122-2h-14.75A2.128,2.128,0,0,0,485.5,351.5Z"/>
                        </g>
                    </g>
                    <g id="Rectangle_47" data-name="Rectangle 47">
                        <g id="Group_45" data-name="Group 45">
                            <path id="Path_50" data-name="Path 50" className="cls-1"
                                  d="M502,430H488a5,5,0,0,1-5-5v-3h24v3A5,5,0,0,1,502,430Z"/>
                        </g>
                        <g id="Group_46" data-name="Group 46">
                            <path id="Path_51" data-name="Path 51" className="cls-2"
                                  d="M502,430H488a5.006,5.006,0,0,1-5-5v-.5a2.5,2.5,0,0,1,2.5-2.5h19a2.5,2.5,0,0,1,2.5,2.5v.5A5.006,5.006,0,0,1,502,430Zm-16.5-5.5v.5a2.5,2.5,0,0,0,2.5,2.5h14a2.5,2.5,0,0,0,2.5-2.5v-.5Z"/>
                        </g>
                    </g>
                    <g id="Path_6" data-name="Path 6">
                        <g id="Group_47" data-name="Group 47">
                            <path id="Path_52" data-name="Path 52" className="cls-1"
                                  d="M494.625,422.927a162.09,162.09,0,0,1-23.375-1.988V355.071a171.205,171.205,0,0,1,23.375-1.879,165.914,165.914,0,0,1,23.125,1.876v65.874A157.2,157.2,0,0,1,494.625,422.927Z"/>
                        </g>
                        <g id="Group_48" data-name="Group 48">
                            <path id="Path_53" data-name="Path 53" className="cls-2"
                                  d="M494.625,354.442a168.177,168.177,0,0,0-22.125,1.71v63.715a158.951,158.951,0,0,0,22.125,1.81,154.434,154.434,0,0,0,21.875-1.8V356.146a163.235,163.235,0,0,0-21.875-1.7m0-2.5A166.682,166.682,0,0,1,519,354v68a157.842,157.842,0,0,1-24.375,2.177A163.222,163.222,0,0,1,470,422V354A172.371,172.371,0,0,1,494.625,351.942Z"/>
                        </g>
                    </g>
                </g>
                <g id="Path_7" data-name="Path 7">
                    <path id="Path_54" data-name="Path 54" className="cls-2"
                          d="M494.746,407.357c-11.823,0-23.8-.994-23.917-1l.209-2.492c.118.01,11.993,1,23.708,1s23.59-.986,23.709-1l.209,2.492C518.544,406.363,506.568,407.357,494.746,407.357Z"/>
                </g>
                <g id="Ellipse_1" data-name="Ellipse 1">
                    <g id="Group_49" data-name="Group 49">
                        <circle id="Ellipse_8" data-name="Ellipse 8" className="cls-1" cx="4" cy="4" r="4"
                                transform="translate(491 411)"/>
                    </g>
                    <g id="Group_50" data-name="Group 50">
                        <path id="Path_55" data-name="Path 55" className="cls-2"
                              d="M495,419a4,4,0,1,1,4-4A4,4,0,0,1,495,419Zm0-5.5a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,495,413.5Z"/>
                    </g>
                </g>
                <g id="fire">
                    <path id="Path_56" data-name="Path 56" className="cls-3"
                          d="M495.161,389.5a9.439,9.439,0,0,1-9.4-9.455,11.609,11.609,0,0,1,1.67-5.6,29.511,29.511,0,0,0,1.487-3.33l.494-1.363s1.391-2.4,1.21.3l-.191,1.43a6.82,6.82,0,0,0,.588,3.655,5.709,5.709,0,0,0,.8,1.279,16.673,16.673,0,0,1,.582-5.485,15.184,15.184,0,0,1,2.467-5,13.811,13.811,0,0,1,3.736-3.165s1.932-.608,2.1-.283-1.416,1.582-1.416,1.582a3.907,3.907,0,0,0-1,2.611c0,1.866,1.145,3.19,2.47,4.724a18.937,18.937,0,0,1,2.542,3.466,10.745,10.745,0,0,1,1.255,5.18A9.437,9.437,0,0,1,495.161,389.5Zm-6.029-14.961q-.153.311-.3.6a10.247,10.247,0,0,0-1.5,4.9,7.834,7.834,0,0,0,15.668,0v0c0-3.67-1.736-5.679-3.416-7.621-1.466-1.7-2.852-3.3-2.852-5.748a5.3,5.3,0,0,1,.029-.577,13.024,13.024,0,0,0-1.346,1.822A14.73,14.73,0,0,0,493.554,378l.207,1.372-1.28-.528a5.814,5.814,0,0,1-2.884-3.05,8.617,8.617,0,0,1-.464-1.254Z"/>
                    <path id="Path_57" data-name="Path 57" className="cls-3"
                          d="M495.162,389.751h0a9.7,9.7,0,0,1-9.649-9.706,11.9,11.9,0,0,1,1.706-5.729,29.357,29.357,0,0,0,1.467-3.288l.493-1.36c.291-.509.82-1.272,1.294-1.129.345.105.466.563.4,1.53l-.193,1.447a6.567,6.567,0,0,0,.567,3.517,5.481,5.481,0,0,0,.293.558,16.911,16.911,0,0,1,.621-4.726,15.4,15.4,0,0,1,2.508-5.083,14.018,14.018,0,0,1,3.811-3.231c2.184-.693,2.375-.322,2.447-.182s.262.508-1.483,1.893a3.652,3.652,0,0,0-.9,2.415c0,1.772,1.115,3.062,2.406,4.557a19.189,19.189,0,0,1,2.573,3.509,11.023,11.023,0,0,1,1.288,5.307A9.7,9.7,0,0,1,495.162,389.751ZM490.3,369a3.832,3.832,0,0,0-.671.876l-.475,1.323a30,30,0,0,1-1.5,3.361,11.406,11.406,0,0,0-1.642,5.493,9.147,9.147,0,1,0,18.294-.008,10.524,10.524,0,0,0-1.226-5.063,18.7,18.7,0,0,0-2.505-3.413c-1.365-1.58-2.536-2.936-2.536-4.894A4.151,4.151,0,0,1,499.1,363.9a7.7,7.7,0,0,0,1.315-1.263,6.082,6.082,0,0,0-1.738.369,13.605,13.605,0,0,0-3.618,3.086A14.883,14.883,0,0,0,492.642,371a16.39,16.39,0,0,0-.574,5.4l.047.765-.489-.591a6.008,6.008,0,0,1-.834-1.334,7.061,7.061,0,0,1-.609-3.788l.192-1.434C490.432,369.156,490.3,369.008,490.3,369Zm4.868,19.123h0a8.085,8.085,0,0,1-8.083-8.085,10.522,10.522,0,0,1,1.537-5.03c.088-.175.187-.374.289-.58l.154-.313.293.293.018.063a8.362,8.362,0,0,0,.451,1.217,5.563,5.563,0,0,0,2.764,2.93l.86.354-.141-.936a14.98,14.98,0,0,1,1.893-10.25,13.423,13.423,0,0,1,1.373-1.86l.515-.583-.079.774a5.332,5.332,0,0,0-.027.551c0,2.348,1.349,3.913,2.777,5.569,1.723,1.992,3.491,4.035,3.491,7.8a8.085,8.085,0,0,1-8.083,8.085ZM489.083,375.2l-.028.054a10.058,10.058,0,0,0-1.473,4.8,7.585,7.585,0,0,0,7.583,7.574h0a7.583,7.583,0,0,0,7.583-7.583c0-3.582-1.706-5.553-3.355-7.459-1.476-1.712-2.858-3.316-2.912-5.766a13.289,13.289,0,0,0-.855,1.229,14.488,14.488,0,0,0-1.828,9.912l.274,1.814-1.688-.7a6.051,6.051,0,0,1-3.02-3.186C489.263,375.668,489.168,375.437,489.083,375.2Z"/>
                </g>
                <g id="Rectangle_48" data-name="Rectangle 48">
                    <g id="Group_53" data-name="Group 53">
                        <path id="Path_59" data-name="Path 59" className="cls-1"
                              d="M492.5,396h7a2.5,2.5,0,0,1,2.5,2.5h0a2.5,2.5,0,0,1-2.5,2.5h-7a2.5,2.5,0,0,1-2.5-2.5h0A2.5,2.5,0,0,1,492.5,396Z"/>
                    </g>
                    <g id="Group_54" data-name="Group 54">
                        <path id="Path_60" data-name="Path 60" className="cls-2"
                              d="M499.5,401h-7a2.5,2.5,0,0,1,0-5h7a2.5,2.5,0,0,1,0,5Zm-7-3.95a1.45,1.45,0,0,0,0,2.9h7a1.45,1.45,0,0,0,0-2.9Z"/>
                    </g>
                </g>
                <g id="Path_18" data-name="Path 18">
                    <path id="Path_61" data-name="Path 61" className="cls-2"
                          d="M488.913,349.115a1.25,1.25,0,0,1-1.25-1.25V336.822a1.25,1.25,0,0,1,2.5,0v11.043A1.249,1.249,0,0,1,488.913,349.115Z"/>
                </g>
                <g id="Path_21" data-name="Path 21">
                    <path id="Path_62" data-name="Path 62" className="cls-2"
                          d="M500.913,440.724a1.25,1.25,0,0,1-1.25-1.25V429.306a1.25,1.25,0,0,1,2.5,0v10.168A1.249,1.249,0,0,1,500.913,440.724Z"/>
                </g>
                <g id="Path_19" data-name="Path 19">
                    <path id="Path_63" data-name="Path 63" className="cls-2"
                          d="M500.913,349.115a1.25,1.25,0,0,1-1.25-1.25V336.822a1.25,1.25,0,0,1,2.5,0v11.043A1.249,1.249,0,0,1,500.913,349.115Z"/>
                </g>
                <g id="Path_20" data-name="Path 20">
                    <path id="Path_64" data-name="Path 64" className="cls-2"
                          d="M488.913,440.724a1.25,1.25,0,0,1-1.25-1.25V429.306a1.25,1.25,0,0,1,2.5,0v10.168A1.249,1.249,0,0,1,488.913,440.724Z"/>
                </g>
            </g>
        </svg>
    )
}
export default HeatingSvg;