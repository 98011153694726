import React from "react";

function MailSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26.256" height="20" viewBox="0 0 26.256 20">
            <g id="mail" transform="translate(0 -61)">
                <g id="Group_31" data-name="Group 31" transform="translate(0 61)">
                    <path id="Path_36" data-name="Path 36" className="cls-1"
                          d="M23.949,61H2.308A2.312,2.312,0,0,0,0,63.308V78.692A2.311,2.311,0,0,0,2.308,81H23.949a2.311,2.311,0,0,0,2.308-2.308V63.308A2.311,2.311,0,0,0,23.949,61Zm-.319,1.538L13.177,72.991,2.634,62.538ZM1.538,78.374V63.619l7.409,7.346Zm1.088,1.088,7.414-7.414,2.6,2.575a.769.769,0,0,0,1.085,0l2.533-2.533,7.374,7.374Zm22.092-1.088L17.344,71l7.374-7.374Z"
                          transform="translate(0 -61)"/>
                </g>
            </g>
        </svg>
    )
}

export default MailSvg;