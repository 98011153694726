import React from "react";

function CloseSvg() {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="close" transform="translate(0 -0.001)">
                <g id="Group_38" data-name="Group 38" transform="translate(0 0.001)">
                    <path id="Path_47" data-name="Path 47" d="M13.326,12l10.4-10.4A.938.938,0,1,0,22.4.275L12,10.675,1.6.275A.938.938,0,0,0,.274,1.6L10.674,12,.274,22.4A.938.938,0,0,0,1.6,23.726L12,13.327l10.4,10.4A.938.938,0,1,0,23.725,22.4Z" transform="translate(0 -0.001)"/>
                </g>
            </g>
        </svg>

    )
}
export default CloseSvg;