import React from "react";



function HeatingSection(props) {
    return (
        <section className="readMore" id="heating">
            <div className="row">
                <div className="col-12 col-lg-6">
                    <div className="section-bg" style={{backgroundImage: `url(../static/images${props.json.img}`}}>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="service-description">
                    <h3 className="text-uppercase">{props.json.title}</h3>
                    {props.json && props.json.texts && props.json.texts.map((text,i) => <p key={i} className="sm">{text}</p>
                    )}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HeatingSection;