import React from "react";
import SectionTitle from "./SectionTitle";
import Footer from "./Footer";
import MailSvg from "../svg/mail";
import WhatsappSvg from "../svg/whatsapp";
import ViberSvg from "../svg/viber";
import TelegramSvg from "../svg/telegram";
import FbSvg from "../svg/facebook";

function Contact(props) {
    return (
        <>
            <section className="contactSection" id="contact">
                <div className="container">
                    <SectionTitle title={props.json.title}/>
                    <div className="row">
                        <div className="col-12 col-lg-4 offset-lg-5">
                            <h3 className="text-uppercase">{props.json.get_started}</h3>
                            <div dangerouslySetInnerHTML={{
                                __html:
                                props.json.contactText
                            }}/>
                            <ul className="list-unstyled">
                                <li><MailSvg/><span>info@hvacsystems.am</span></li>
                                <li><WhatsappSvg/><span>+37498505067</span></li>
                                <li><ViberSvg/><span>+37498505067</span></li>
                                <li><TelegramSvg/><span>+37498505067</span></li>
                            </ul>
                            <p className='sm fb'>{props.json.we_are_on} <FbSvg/> {localStorage.language == "hy" ? "-ում ենք" : null}</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer json={props.json}/>

        </>
    )
}

export default Contact;