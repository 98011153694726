import React from "react";
import {Link} from "react-scroll";
import CloseSvg from "../svg/Close";


function MobileMenu(props) {
    function closeMobileMenu(lang){
        props.changeLang(lang);
        props.closeMenu();
    }
    return (
        <nav className={`mobileMenu d-lg-none ${props.menuIsOpen ? "open" : ""}`}>

<button className="closeBtn" onClick={props.closeMenu}><CloseSvg/></button>
                <ul className="navbar-nav">
                    {props.json && props.json.menu && props.json.menu.map(item => {
                        return (

                            <li key={"mobile-" +item.title}><Link className="nav-item nav-link"
                                                       to={item.url} smooth={true} duration={500} onClick={props.closeMenu}>{item.title}
                                <span className="sr-only">(current)</span> </Link>
                            </li>
                        )
                    })}

                </ul>

            <div className="langList">
            <ul className="list-unstyled">
                {props.langs && props.langs.map(lang => (<li key={"mobile-" + lang.label} onClick={() => closeMobileMenu(lang.name)}><span>{lang.label}</span></li> ))}

            </ul>
            </div>
        </nav>
    )
}

export default MobileMenu;