import React, {useState, useEffect} from "react";
import {Carousel} from 'react-bootstrap';
import ModalSliderItem from "./ModalSliderItem";
import ArrowRightSvg from "../../svg/arrowRight";
import ArrowLeftSvg from "../../svg/arrowLeft";

function ControlledCarousel(props) {
    const [index, setIndex] = useState(props.slideIndex);
    const [direction, setDirection] = useState(null);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
        setDirection(e.direction);
    };

    useEffect(() => {
        setIndex(props.slideIndex)
    }, [props.slideIndex]);

    return (
        <Carousel fade={true} activeIndex={index} direction={direction}
                  onSelect={handleSelect}
                  interval={null}
                  nextIcon={<span aria-hidden="true"><ArrowRightSvg/></span>}
                  prevIcon={<span aria-hidden="true"><ArrowLeftSvg/></span>}>
            {props.json && props.json.map((work, index) => {
                return (
                    <Carousel.Item key={"slider-" + index}>
                        <ModalSliderItem title={work.title} text={work.text} img={work.img}
                                         closeModal={props.hideModal}/>
                    </Carousel.Item>
                )
            })}
        </Carousel>
    );
}

export default ControlledCarousel;