import React from "react";

function ArrowLeftSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="7.41" height="13" viewBox="0 0 7.41 13">
            <g id="arrow-point-to-left" transform="translate(7.41 13) rotate(180)">
                <path id="Path_47" data-name="Path 47" className="cls-1"
                      d="M7.144,7.144l-5.59,5.59A.91.91,0,1,1,.267,11.446L5.213,6.5.267,1.554A.91.91,0,0,1,1.554.267l5.59,5.59a.91.91,0,0,1,0,1.287Z"/>
            </g>
        </svg>

    )
}

export default ArrowLeftSvg;