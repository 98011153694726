import React from "react";
import SectionTitle from "./SectionTitle";
import bg from "../static/images/description-bg.png";
import HeatingSvg from "../svg/heating";
import TextTruncate from 'react-text-truncate';
import {Link} from 'react-scroll';
import VentilationSvg from "../svg/ventilation";
import AirConditioningSvg from "../svg/airConditioner";
import VentilationSection from "./Ventilation";
import HeatingSection from "./Heating";
import AirConditioning from "./AirConditioning";

function Services(props) {
    return (
        <section id="services">
            <div className="container">
                <SectionTitle title={props.json && props.json.title}/>
                <div className="services">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="serviceDescription section-bg" style={{backgroundImage: `url(${bg})`}}>
                                <div className="descriptionText">
                                <h3>{props.json && props.json.what_we_do}</h3>
                                <p className="sm">{props.json && props.json.hvac_description}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="allServices verticalAlign">
                                <div className="row">
                                    <div className="col-12 col-sm-4">
                                        <span className="iconSpan"> <HeatingSvg/> </span>
                                    </div>
                                    <div className="col-12 col-sm-8">
                                        <div className="shortDescription">
                                            <h3>{props.json && props.json.heating && props.json.heating.title}</h3>
                                            <TextTruncate
                                                line={3}
                                                element="span"
                                                truncateText="…"
                                                text={props.json && props.json.heating && props.json.heating.texts && props.json.heating.texts[0]}
                                                textTruncateChild={<Link
                                                    to={props.json && props.json.heating && props.json.heating.url}
                                                    smooth={true} duration={500}
                                                    className="read-more">{props.json && props.json.read_more} </Link>}
                                            />

                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-4">
                                        <span className="iconSpan"> <VentilationSvg/> </span>
                                    </div>
                                    <div className="col-12 col-sm-8">
                                        <div className="shortDescription">
                                            <h3>{props.json && props.json.ventilation && props.json.ventilation.title}</h3>
                                            <TextTruncate
                                                line={3}
                                                element="span"
                                                truncateText="…"
                                                text={props.json && props.json.ventilation && props.json.ventilation.texts && props.json.ventilation.texts[0]}
                                                textTruncateChild={<Link
                                                    to={props.json && props.json.ventilation && props.json.ventilation.url}
                                                    smooth={true} duration={500}
                                                    className="read-more">{props.json && props.json.read_more} </Link>}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-4">
                                        <span className="iconSpan"> <AirConditioningSvg/> </span>
                                    </div>
                                    <div className="col-12 col-sm-8">
                                        <div className="shortDescription">
                                            <h3>{props.json && props.json.air_conditioning && props.json.air_conditioning.title}</h3>
                                            <TextTruncate
                                                line={3}
                                                element="span"
                                                truncateText="…"
                                                text={props.json && props.json.heating && props.json.air_conditioning.texts && props.json.air_conditioning.texts[0]}
                                                textTruncateChild={<Link
                                                    to={props.json && props.json.air_conditioning && props.json.air_conditioning.url}
                                                    smooth={true} duration={500}
                                                    className="read-more">{props.json && props.json.read_more} </Link>}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="factors">
                    <h3 className="text-uppercase">{props.json && props.json.why_choose_us && props.json.why_choose_us.title}</h3>
                    <p className="sm">{props.json && props.json.why_choose_us && props.json.why_choose_us.text}</p>
                </div>
                <HeatingSection json={props.json.heating}/>
                <div className="installations">
                    <h3 className="text-uppercase">{props.json && props.json.hvac_installations && props.json.hvac_installations.title}</h3>
                    <p className="sm"> {props.json && props.json.hvac_installations && props.json.hvac_installations.text}</p>
                </div>
                <VentilationSection json={props.json.ventilation}/>
                <AirConditioning json={props.json.air_conditioning}/>
            </div>
        </section>
    )
}

export default Services;