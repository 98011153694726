import React from "react";
import {Link} from 'react-scroll'

function Home(props) {
    return (
        <section className="homeSection" style={{backgroundImage: `url(../static/images/${props.json.img})`}} id="Home">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="homeInfo">
                            <h1>{props.json.title}</h1>
                            <p>{props.json.description}</p>
                            <Link to="services" smooth={true} duration={500}
                                  className="btn btn-lightblue">{props.json.view_services}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Home;