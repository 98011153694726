import React from "react";

function ViberSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23.831" height="26.02" viewBox="0 0 23.831 26.02">
            <g id="viber" transform="translate(-1.38 0.013)">
                <path id="Path_37" data-name="Path 37" className="cls-1"
                      d="M24.967,15.05c.776-6.529-.373-10.65-2.444-12.514h0C19.182-.649,7.892-1.12,3.893,2.678,2.1,4.535,1.465,7.262,1.4,10.637s-.152,9.7,5.742,11.417h.005l-.005,2.62s-.04,1.062.638,1.275c.776.251,1.127-.242,3.539-3.123a22.074,22.074,0,0,0,7.484-.569c.815-.273,5.424-.883,6.169-7.208ZM11.712,20.983s-2.553,3.184-3.347,4.01c-.26.269-.545.244-.541-.289,0-.35.019-4.35.019-4.35-5-1.432-4.7-6.818-4.648-9.635s.57-5.127,2.091-6.681C8.795.747,18.692,1.483,21.214,3.853,24.3,6.586,23.2,14.307,23.206,14.57c-.634,5.28-4.369,5.614-5.055,5.843a20.365,20.365,0,0,1-6.439.57Z"
                      transform="translate(0 0)"/>
                <path id="Path_38" data-name="Path 38" className="cls-1"
                      d="M12.222,4.3a.3.3,0,0,0,0,.605c2.987.023,5.447,2.105,5.474,5.924a.293.293,0,1,0,.585-.005h0c-.032-4.115-2.718-6.5-6.058-6.524Z"
                      transform="translate(1.234 0.501)"/>
                <path id="Path_39" data-name="Path 39" className="cls-1"
                      d="M16.151,10.193a.293.293,0,1,0,.585.014,4.05,4.05,0,0,0-3.979-4.335.3.3,0,0,0-.041.605,3.452,3.452,0,0,1,3.435,3.716Z"
                      transform="translate(1.144 0.607)"/>
                <path id="Path_40" data-name="Path 40" className="cls-1"
                      d="M16.979,12.774a1.186,1.186,0,0,0-1.394.173l-.5.563a.99.99,0,0,1-.734.248,6.147,6.147,0,0,1-4.42-3.951.769.769,0,0,1,.277-.656l.629-.45a.907.907,0,0,0,.193-1.247A13.192,13.192,0,0,0,9.3,5.3a1.03,1.03,0,0,0-1.252-.183h-.01c-1,.506-2.1,1.453-1.746,2.428a16.277,16.277,0,0,0,5.2,6.781,21.032,21.032,0,0,0,5.358,2.648l.01.014c1.09.314,2.15-.67,2.715-1.561v-.007a.78.78,0,0,0-.2-1.106,13.7,13.7,0,0,0-2.4-1.542Z"
                      transform="translate(0 0.836)"/>
                <path id="Path_41" data-name="Path 41" className="cls-1"
                      d="M13.169,8.1c.961.056,1.427.558,1.477,1.589a.293.293,0,1,0,.585-.028A2.007,2.007,0,0,0,13.2,7.5a.3.3,0,0,0-.032.605Z"
                      transform="translate(1.072 0.697)"/>
            </g>
        </svg>

    )
}

export default ViberSvg;