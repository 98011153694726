import React, {useEffect, useState} from 'react';
import './App.css';
import Contact from './components/ContactUs';
import english from './static/translates/en';
import armenian from './static/translates/arm';
import russian from './static/translates/rus';
import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import Works from "./components/Works";
import Services from "./components/Services";


let langs = [
    {"name": "en", "label": "Eng"},
    {"name": "hy", "label": "Հայ"},
    {"name": "ru", "label": "Рус"}
];
let mobileLangs = [
    {"name": "en", "label": "Eng"},
    {"name": "hy", "label": "Հայ"},
    {"name": "ru", "label": "Рус"}
];


function App() {
    const [currentLang, setCurrentLang] = useState(localStorage.language || "en");

    useEffect(() => {
        if (localStorage.language == "") {
            localStorage.setItem('language', 'en');
        }
    }, []);


    const changeLang = (index) => {
        if (typeof index == "number") {
            langs = langs.slice(index).concat(langs.slice(0, index));
            localStorage.setItem('language', langs[0].name);
            setCurrentLang(langs[0].name);
        } else {
            localStorage.setItem('language', index);
            setCurrentLang(index);
        }

    };

    const detectJson = () => {
        switch (currentLang) {
            case 'ru':
                return russian;
            case 'en':
                return english;
            case 'hy':
                return armenian;
            default:
                return english;
        }
    };
    let json = detectJson();
    return (
        <div className="">
            <Header json={json.header} changeLang={changeLang.bind(this)} langs={langs}
                    mobileLangs={mobileLangs}/>
            <Home json={json.home}/>
            <About json={json.about}/>
            <Services json={json.services}/>
            <Works json={json.our_works}/>
            <Contact json={json.footer}/>
        </div>
    );
}

export default App;
