import React from "react";
import SectionTitle from "./SectionTitle";
import { Link } from 'react-scroll'
function About(props) {

    return (
        <section className="aboutSection" id="about">
            <div className="container">
                <SectionTitle title={props.json.title}/>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="section-bg" style={{backgroundImage: `url(../static/images/${props.json.img})`}}>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="verticalAlign">
                            {props.json && props.json.text.map(item => (<p key={item} className="sm">{item}</p>))}
                            <div dangerouslySetInnerHTML={{
                                __html:
                                props.json.contactText
                            }}/>
                            <Link to="contact" smooth={true} duration={500} className="btn btn-lightblue">{props.json.contact}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;