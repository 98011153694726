import React from "react";

function VentilationSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="64.827" height="64.827" viewBox="0 0 64.827 64.827">
            <g id="ventilation-icon" transform="translate(-463 -356)">
                <g id="ventilation-2" data-name="ventilation">
                    <g id="Path_8" data-name="Path 8">
                        <path id="Path_66" data-name="Path 66"
                              d="M526.877,356H463.95a.949.949,0,0,0-.95.948v20.669a.95.95,0,0,0,1.9,0V357.9h61.027v61.027H464.9v-50.9a.95.95,0,1,0-1.9,0h0v51.85a.949.949,0,0,0,.948.95h62.929a.949.949,0,0,0,.95-.948V356.95a.949.949,0,0,0-.948-.95Z"/>
                    </g>
                    <g id="Path_13" data-name="Path 13">
                        <path id="Path_67" data-name="Path 67"
                              d="M497.531,361.126a1.028,1.028,0,0,0-1.1.953c0,.021,0,.041,0,.062v16.418a1.018,1.018,0,0,0,.812,1,9.034,9.034,0,0,1,7.027,7.027,1.019,1.019,0,0,0,1,.812h16.418a1.029,1.029,0,0,0,1.018-1.038l0-.062A27.373,27.373,0,0,0,497.531,361.126Zm1.279,6.3a21.493,21.493,0,0,1,17.641,17.934h-2.058a19.44,19.44,0,0,0-15.926-15.925v-2.06l.338.052Zm-.338,10.335v-2.1a13.02,13.02,0,0,1,2.613.927,1.018,1.018,0,1,0,.883-1.835,15.068,15.068,0,0,0-3.5-1.18V371.5a17.387,17.387,0,0,1,13.863,13.86h-2.072a15.169,15.169,0,0,0-4.073-7.594,1.018,1.018,0,0,0-1.448,1.432h0a13.124,13.124,0,0,1,3.434,6.163h-2.1a11.067,11.067,0,0,0-7.6-7.6Zm20.041,7.6a23.553,23.553,0,0,0-20.041-20.041v-2.056a25.342,25.342,0,0,1,22.1,22.1Z"/>
                    </g>
                    <g id="Path_14" data-name="Path 14">
                        <path id="Path_68" data-name="Path 68"
                              d="M493.3,361.125A27.375,27.375,0,0,0,468.126,386.3a1.029,1.029,0,0,0,.953,1.1l.062,0h16.418a1.018,1.018,0,0,0,1-.812,9.032,9.032,0,0,1,7.027-7.027,1.018,1.018,0,0,0,.812-1V362.14a1.028,1.028,0,0,0-1.038-1.017l-.062,0Zm-1.383,12.549a15.359,15.359,0,0,0-11.342,11.685H478.5a17.386,17.386,0,0,1,13.859-13.86v2.075ZM477.5,370.5a25.318,25.318,0,0,1,14.858-7.237v2.055a23.173,23.173,0,0,0-4.47,1.047,1.019,1.019,0,0,0,.659,1.926,21.133,21.133,0,0,1,3.81-.917v2.058a19.44,19.44,0,0,0-15.924,15.927h-2.059a21.455,21.455,0,0,1,9.641-14.885,1.018,1.018,0,1,0-1.1-1.717,23.513,23.513,0,0,0-10.6,16.6h-2.057A25.321,25.321,0,0,1,477.5,370.5Zm7.261,14.858h-2.1a13.288,13.288,0,0,1,9.7-9.7v2.1a11.064,11.064,0,0,0-7.6,7.6Z"/>
                    </g>
                    <g id="Path_15" data-name="Path 15">
                        <path id="Path_69" data-name="Path 69"
                              d="M522.431,389.757a1.018,1.018,0,0,0-.747-.326H505.267a1.018,1.018,0,0,0-1,.812,9.034,9.034,0,0,1-7.027,7.027,1.018,1.018,0,0,0-.812,1v16.418A1.043,1.043,0,0,0,497.5,415.7h.031A27.374,27.374,0,0,0,522.7,390.531,1.023,1.023,0,0,0,522.431,389.757ZM512.2,392.1a17.39,17.39,0,0,1-13.731,13.23v-2.072a15.359,15.359,0,0,0,11.787-11.787h2.077c-.041.209-.085.418-.132.629Zm-6.135-.634h2.1a13.288,13.288,0,0,1-9.7,9.7v-2.1a11.066,11.066,0,0,0,7.6-7.6Zm7.261,14.858a25.318,25.318,0,0,1-14.858,7.237v-2.055a23.209,23.209,0,0,0,6.032-1.646,1.019,1.019,0,1,0-.8-1.874h0a21.163,21.163,0,0,1-5.235,1.463v-2.059a19.438,19.438,0,0,0,15.925-15.925h2.06a21.464,21.464,0,0,1-8.346,13.989,1.018,1.018,0,0,0,1.218,1.631,23.512,23.512,0,0,0,9.183-15.62h2.055a25.318,25.318,0,0,1-7.233,14.859Z"/>
                    </g>
                    <g id="Path_16" data-name="Path 16">
                        <path id="Path_70" data-name="Path 70"
                              d="M486.556,390.243a1.018,1.018,0,0,0-1-.812H469.141a1.028,1.028,0,0,0-1.017,1.038c0,.021,0,.041,0,.062A27.373,27.373,0,0,0,493.3,415.7a1.043,1.043,0,0,0,1.1-.984v-16.45a1.018,1.018,0,0,0-.812-1,9.032,9.032,0,0,1-7.027-7.027Zm5.657,19.188a21.492,21.492,0,0,1-17.837-17.964h2.058a19.256,19.256,0,0,0,3.787,8.711,1.018,1.018,0,0,0,1.65-1.193l-.042-.054a17.247,17.247,0,0,1-3.33-7.463h2.077a15.359,15.359,0,0,0,11.787,11.787v2.071a17.2,17.2,0,0,1-6.427-2.585,1.02,1.02,0,0,0-1.126,1.7h0a19.271,19.271,0,0,0,7.554,2.955v2.058Zm.147-10.365v2.1a13.288,13.288,0,0,1-9.7-9.7h2.1a11.062,11.062,0,0,0,7.6,7.6Zm0,14.5a25.342,25.342,0,0,1-22.1-22.1h2.055a23.553,23.553,0,0,0,20.041,20.041v2.055Z"/>
                    </g>
                    <g id="Path_17" data-name="Path 17">
                        <path id="Path_71" data-name="Path 71" className="cls-1"
                              d="M488.877,388.413a6.536,6.536,0,1,0,6.536-6.536h0A6.543,6.543,0,0,0,488.877,388.413Zm11.174,0a4.637,4.637,0,1,1-4.637-4.637h0A4.642,4.642,0,0,1,500.051,388.413Z"/>
                    </g>
                    <g id="Ellipse_3" data-name="Ellipse 3">
                        <g id="Group_57" data-name="Group 57">
                            <circle id="Ellipse_11" data-name="Ellipse 11" className="cls-2" cx="2.5" cy="2.5" r="2.5"
                                    transform="translate(468.413 361.413)"/>
                        </g>
                        <g id="Group_58" data-name="Group 58">
                            <path id="Path_72" data-name="Path 72" className="cls-3"
                                  d="M470.913,366.413a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,470.913,366.413Zm0-4a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,470.913,362.413Z"/>
                        </g>
                    </g>
                    <g id="Ellipse_6" data-name="Ellipse 6">
                        <g id="Group_59" data-name="Group 59">
                            <circle id="Ellipse_12" data-name="Ellipse 12" className="cls-2" cx="2.5" cy="2.5" r="2.5"
                                    transform="translate(468.413 410.413)"/>
                        </g>
                        <g id="Group_60" data-name="Group 60">
                            <path id="Path_73" data-name="Path 73" className="cls-3"
                                  d="M470.913,415.413a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,470.913,415.413Zm0-4a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,470.913,411.413Z"/>
                        </g>
                    </g>
                    <g id="Ellipse_4" data-name="Ellipse 4">
                        <g id="Group_61" data-name="Group 61">
                            <circle id="Ellipse_13" data-name="Ellipse 13" className="cls-2" cx="2.5" cy="2.5" r="2.5"
                                    transform="translate(517.413 361.413)"/>
                        </g>
                        <g id="Group_62" data-name="Group 62">
                            <path id="Path_74" data-name="Path 74" className="cls-3"
                                  d="M519.913,366.413a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,519.913,366.413Zm0-4a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,519.913,362.413Z"/>
                        </g>
                    </g>
                    <g id="Ellipse_5" data-name="Ellipse 5">
                        <g id="Group_63" data-name="Group 63">
                            <circle id="Ellipse_14" data-name="Ellipse 14" className="cls-2" cx="2.5" cy="2.5" r="2.5"
                                    transform="translate(517.413 410.413)"/>
                        </g>
                        <g id="Group_64" data-name="Group 64">
                            <path id="Path_75" data-name="Path 75" className="cls-3"
                                  d="M519.913,415.413a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,519.913,415.413Zm0-4a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,519.913,411.413Z"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    )
}

export default VentilationSvg;