import React from "react";


function ModalSliderItem(props) {
    return (
        <div>
            <div className="sliderHeader">
                <p>{props.title}</p>
            </div>
            <div className="modalImg" style={{backgroundImage: `url(../static/images${props.img}`}}></div>
            <div className="sliderDescription" dangerouslySetInnerHTML={{__html: props.text}}/>
        </div>
    )
}

export default ModalSliderItem;