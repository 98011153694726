import React from 'react';


function Footer() {
  return (
      <footer className="text-center">
          <p>&copy; {new Date().getFullYear()} HVACSYSTEMS. All rights reserved.</p>
      </footer>
  )
}

export default Footer;