import React from "react";

function WhatsappSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26.256" height="26.383" viewBox="0 0 26.256 26.383">
            <defs>
                <style>

                </style>
            </defs>
            <path id="whatsapp" className="cls-1"
                  d="M22.681,3.834A13.076,13.076,0,0,0,2.1,19.608L.25,26.383l6.93-1.818a13.059,13.059,0,0,0,6.247,1.591h.005A13.079,13.079,0,0,0,22.681,3.834ZM13.433,23.948h0A10.853,10.853,0,0,1,7.9,22.433L7.5,22.2,3.389,23.277l1.1-4.01-.258-.411a10.866,10.866,0,1,1,9.2,5.092Zm5.96-8.138c-.327-.164-1.933-.954-2.232-1.063s-.517-.163-.735.164-.844,1.063-1.034,1.281-.381.245-.708.082a8.922,8.922,0,0,1-2.627-1.621,9.853,9.853,0,0,1-1.817-2.263c-.19-.327,0-.487.143-.667a9.24,9.24,0,0,0,.817-1.117.6.6,0,0,0-.027-.572c-.081-.163-.735-1.771-1.007-2.426-.265-.637-.534-.551-.735-.561s-.408-.011-.626-.011a1.2,1.2,0,0,0-.871.409A3.664,3.664,0,0,0,6.79,10.169a6.354,6.354,0,0,0,1.334,3.379A14.561,14.561,0,0,0,13.7,18.48a18.7,18.7,0,0,0,1.862.688,4.478,4.478,0,0,0,2.057.129,3.365,3.365,0,0,0,2.2-1.553,2.729,2.729,0,0,0,.19-1.553c-.081-.136-.3-.218-.626-.381Zm0,0"
                  transform="translate(-0.25)"/>
        </svg>

    )
}
export default WhatsappSvg;