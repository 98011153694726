import React from "react";

function BurgerMenu() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="18" viewBox="0 0 32 18">
            <g id="burger-menu" transform="translate(-325 -15)">
                <rect id="Rectangle_55" data-name="Rectangle 55" className="cls-1" width="32" height="2" rx="1"
                      transform="translate(325 15)"/>
                <rect id="Rectangle_56" data-name="Rectangle 56" className="cls-1" width="22" height="2" rx="1"
                      transform="translate(335 23)"/>
                <rect id="Rectangle_57" data-name="Rectangle 57" className="cls-1" width="32" height="2" rx="1"
                      transform="translate(325 31)"/>
            </g>
        </svg>

    )
}
export default BurgerMenu;