import React from 'react';

import SectionTitle from "./SectionTitle";
import WorksSlider from "./WorksSlider";

function Works(props) {
    return (
        <section id="works">
            <SectionTitle title={props.json.title}/>
            <div className="container">
                <WorksSlider json={props.json.works} viewMore={props.json.view_more}/>
            </div>
        </section>
    )
}

export default Works;