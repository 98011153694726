import React, {useState} from 'react';
import logo from '../static/images/logo.svg';
import Dropdown from 'react-bootstrap/Dropdown';

import {Link} from 'react-scroll'
import ArrowDownSVG from "../svg/arrowDownSvg";
import BurgerMenu from "../svg/burgerMenu";
import MobileMenu from "./MobileMenu";


function Header(props) {
    const [isOpen, setIsOpen] = useState(false);

    function openMenu() {
        setIsOpen(true);
        document.body.addEventListener('click', function(){closeMenu()})
    }

    function closeMenu() {
        setIsOpen(false);
        document.body.removeEventListener('click', function() {closeMenu()});
    }

    let headerMenu = props.json && props.json.menu;
    const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
        <a
            href=""
            ref={ref}
            className="nav-item nav-link"
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <ArrowDownSVG/>
        </a>
    ));
    return (
        <header className="fixed-top">
            <div className="container">
                <nav className="navbar navbar-expand-lg">
                    <Link className="navbar-brand" to="Home"  smooth={true} duration={500}><img src={logo} alt="logo"/></Link>
                    {!isOpen ?
                        <button className="navbar-toggler" type="button" onClick={openMenu}
                                aria-controls="navbarNavAltMarkup" aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"><BurgerMenu/></span>
                        </button>
                        : null
                    }
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <ul className="navbar-nav">
                            {headerMenu && headerMenu.map(item => {
                                return (

                                    <li key={item.title}><Link className="nav-item nav-link"
                                                               to={item.url} smooth={true} duration={500}>{item.title}
                                        <span className="sr-only">(current)</span></Link>
                                    </li>
                                )
                            })}
                            <li className="nav-item dropdown">
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} id="langDropdown" className="headerBtn">
                                        {props.json && props.json.lang}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {props.langs.map((lang, i) => {
                                                return lang.label !== props.json.lang ? (<Dropdown.Item
                                                    onClick={() => props.changeLang(i)}
                                                    key={lang.name}>{lang.label}</Dropdown.Item>) : null
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                        </ul>

                    </div>

                </nav>

                <MobileMenu json={props.json} langs={props.mobileLangs} closeMenu={closeMenu}
                            menuIsOpen={isOpen} changeLang={props.changeLang}/>

            </div>
        </header>
    )
}

export default Header;