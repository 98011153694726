import React from "react";
import {Modal} from "react-bootstrap";
import ControlledCarousel from "./BootstrapCarousel";
import CloseSvg from "../../svg/Close";

function LightboxModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="modal-80w"
        >
            <Modal.Title>
                <button onClick={props.onHide} className="closeBtn"><CloseSvg/></button>
            </Modal.Title>
            <Modal.Body>

                <ControlledCarousel slideIndex={props.slideindex} json={props.json}/>
            </Modal.Body>

        </Modal>
    );
}

export default LightboxModal;