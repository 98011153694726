import React from "react";



function AirConditioning(props) {
    return (
        <section className="ventilation readMore" id="air-conditioning">
            <div className="row">
                <div className="col-12 col-lg-6 order-2 order-lg-1">
                    <div className="section-bg" style={{backgroundImage: `url(../static/images${props.json.img}`}}>
                    </div>
                </div>
                <div className="col-12 col-lg-6 order-1 order-lg-2">
                    <div className="service-description">
                    <h3 className="text-uppercase">{props.json.title}</h3>
                    {props.json && props.json.texts && props.json.texts.map((text,i) => <p className="sm" key={i}>{text}</p>
                    )}
                    </div>
                </div>

            </div>
        </section>
    )
}

export default AirConditioning;