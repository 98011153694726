import React from "react";

function ArrowDownSVG() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="7.052" viewBox="0 0 13 7.052">
            <g id="down-arrow" transform="translate(0.5 -116.785)">
                <g id="Group_1" data-name="Group 1" transform="translate(0 117.336)">
                    <path id="Path_1" data-name="Path 1" className="cls-1"
                          d="M11.854,117.472a.529.529,0,0,0-.707,0L6,122.222.854,117.472a.529.529,0,0,0-.707,0,.436.436,0,0,0,0,.653l5.5,5.077a.53.53,0,0,0,.707,0l5.5-5.077A.436.436,0,0,0,11.854,117.472Z"
                          transform="translate(0 -117.336)"/>
                </g>
            </g>
        </svg>

    )
}

export default ArrowDownSVG;